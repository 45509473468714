import axios from 'axios'

export default {
  institutions(query) {
    return axios
      .get('/api/partner_admin/financial_institutions/search', {
        params: {
          query: query
        },
        handleErrors: true
      })
      .then(res => {
        return res.data.result
      })
  }
}
